import React, { createContext, useContext, useReducer, useState } from 'react';
import { RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import { User } from 'firebase';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, password?: string): Promise<string>;
  getRoom(room: string, password?: string): Promise<string>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
  } as StateContextType;

  contextValue = {
    ...contextValue,
    getToken: async (identity, roomName, password) => {
      const headers = new window.Headers();
      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT;
      const params = new window.URLSearchParams({ identity, roomName });

      const tokenUrl = `${endpoint}/guest/${roomName}/${password}/access_token?${params}`;
      return fetch(tokenUrl, { headers }).then(res => res.text());
    },
    getRoom: async (roomName, password) => {
      const headers = new window.Headers();
      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT;
      const tokenUrl = `${endpoint}/guest/${roomName}/${password}`;
      return fetch(tokenUrl, { headers }).then(res => {
        if (!res.ok) {
          throw res.text();
        }
        return res.text()
      });
    },
  };

  const getToken: StateContextType['getToken'] = (name, room, password) => {
    setIsFetching(true);

    return contextValue
      .getToken(name, room, password)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getRoom: StateContextType['getRoom'] = (room, password) => {
    setIsFetching(true);

    return contextValue
      .getRoom(room, password)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        console.log(err)
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return <StateContext.Provider value={{ ...contextValue, getToken, getRoom }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
