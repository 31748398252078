import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  password: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  setPassword: (password: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}



export default function RoomNameScreen({ name, roomName, password, setName, setRoomName, setPassword, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const { getRoom } = useAppState();


  const handleJoin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(roomName || password){     
      
      getRoom(roomName, password).then((e) => {
        handleSubmit(event);
      });
      
    }
    return false;
    //getToken(name, roomName, password).then(token => (token));
  };

  
  


  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;
  const hasPassword = password;
  const hasRoomname = roomName;


  return (
    <>

      {!hasPassword && !hasRoomname && (

        <div>
          <Typography variant="h5" className={classes.gutterBottom}>
            Your Invitation Is Invalid.
          </Typography>
          <Typography component={'span'} variant="body1">
          Click on the invitation url shared with you.<hr></hr> Your current url is invalid.
          </Typography>
        </div>

      )}

      {!hasUsername && hasRoomname && (

        <div>
          <Typography variant="h5" className={classes.gutterBottom}>
            Join Room
          </Typography>
          <Typography variant="body1">
            {hasUsername
              ? "Enter the name of a room you'd like to join."
              : "Enter your name and the name of a room you'd like to join"}
          </Typography>
        </div>

      )}

      {!hasUsername && hasRoomname && (

        <form onSubmit={(event: FormEvent<HTMLFormElement>) => {event.persist();handleJoin(event)}}>

          <div className={classes.inputContainer}>

            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
                Your Name
            </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              />
            </div>
          </div>
          
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!name || !roomName || !password}
              className={classes.continueButton}
            >
              Continue
          </Button>
          </Grid>

        </form>
      )}
    </>
  );
}
